import { Component, OnInit } from '@angular/core';
import { Vehicle } from 'app/model/vehicle';
import { UserService } from 'app/service/user.service';
import { Router, ActivatedRoute } from '@angular/router';
import { NavbarService } from 'app/service/nav-bar.service';
import { EbiEventService } from 'app/service/ebi-event.service';
import { ProgramSelectService } from 'app/service/program-select.service';
import * as moment from 'moment';
import { environment } from 'environments/environment';
import { WindowRef } from 'app/service/window-ref';
import { Title } from '@angular/platform-browser';
import { BrandingService } from 'app/service/branding.service';
import { RatedMileageService } from 'app/service/rated-mileage.service';
import { Alert } from 'app/enum/alert';
import { LoggerService } from 'app/service/logger.service';
import { SelfEnrollmentService } from 'app/service/self-enrollment.service';
import { ELIGIBLE_SELF_ENROLLMENT_STATUS } from 'app/shared/constants/app.constants';
import { TimeoutModalService } from 'app/session-time-out/time-out.service';
import { TelematicsTransactionsService } from 'app/service/telematics-transactions.service';
import { DataService } from 'app/service/data.service';
import { PolicyNumberFormatService } from 'app/service/policy-number-format.service';

@Component({
    selector: 'app-program-select',
    templateUrl: './program-select.component.html',
    styleUrls: ['./program-select.component.css']
})

export class ProgramSelectComponent implements OnInit {
    orderedVehicles: Vehicle[] = [];
    vehiclesByRow: Vehicle[][];
    futureEffectiveDate = '';
    _window: any;
    shouldShowLoadingMessage: boolean;
    shouldShowFutureDateMessage: boolean;
    shouldShowGeneralErrorMessage: boolean;
    shouldShowSmartRideMobileMessage: boolean;
    connectedCarRowArray = [];
    programTypeArray = [];
    isSmilesVersionTwo = false;
    versionDataArray = [];
    userRole: string;

    // eslint-disable-next-line max-params
    constructor(
        public router: Router,
        public user: UserService,
        public navBar: NavbarService,
        public ebiEvents: EbiEventService,
        public windowRef: WindowRef,
        public title: Title,
        public activatedRoute: ActivatedRoute,
        private programSelectService: ProgramSelectService,
        public mileage: RatedMileageService,
        private logger: LoggerService,
        private selfEnrollmentService: SelfEnrollmentService,
        private timeoutModalService: TimeoutModalService,
        public dataService: DataService
    ) {
        this._window = this.windowRef.nativeWindow;
    }

    ngOnInit(): void {
        this.shouldShowLoadingMessage = true;
        this.shouldShowFutureDateMessage = false;
        this.shouldShowGeneralErrorMessage = false;
        this.shouldShowSmartRideMobileMessage = false;
        if (!BrandingService.getBranding()) {
            const branding = this.activatedRoute.snapshot.data.branding;
            if (branding) {
                BrandingService.setBranding(branding);
            }
        }
        sessionStorage.setItem('selectedProgramType', BrandingService.getBranding() === 'smartride' ? 'sride' : 'smiles');
        this.navBar.headerDisplay(this.user.isLoggedIn, this.router.url);
        if (BrandingService.getBranding() === 'smartride') {
            this.setTitle('SmartRide');
            let allPolicies: any = sessionStorage.getItem('allPolicies');
            if (allPolicies) {
                allPolicies = allPolicies.split(';');
                // Not sure why this is here
                for (const policyNum of allPolicies) {
                    sessionStorage.setItem('selectedPolicy', policyNum);
                    this.ebiEvents.postSrideEvent('425080070');
                }

                sessionStorage.setItem('selectedPolicy', '');
            }
        } else {
            this.ebiEvents.postEvent('Vehicle selection page');
            if (moment(environment.futureDate) < moment(environment.targetLaunchDate) && !this.user.isAdmin()) {
                this.redirectToMarketingPage();
                return;
            }
        }
        const policyNumbers = this.user.getPolicies();
        if (policyNumbers.length < 1) {
            this.redirectToMarketingPage();
            return;
        }
        this.programSelectService.fetchProgramsByPolicyNumbers(policyNumbers).subscribe(
            (program) => {
                if (Object.prototype.hasOwnProperty.call(program, 'mobileProgram')) {
                    if (Object.prototype.hasOwnProperty.call(program.mobileProgram, 'drivers')) {
                        if (program.mobileProgram.drivers.length > 0) {
                            this.receiveProgram(program);
                        }
                    } else {
                        this.receiveProgram(program);
                    }
                } else {
                    this.receiveProgram(program);
                }
            },
            (error) => {
                this.shouldShowGeneralErrorMessage = true;
                if (sessionStorage.getItem('cancelledPolicyPresent') === 'true') {
                    this.shouldShowGeneralErrorMessage = false;
                }
                sessionStorage.setItem('cancelledPolicyPresent', 'false');
                this.logger.error(error);

                this.shouldShowLoadingMessage = false;
                this.shouldShowFutureDateMessage = false;
                this.checkForFutureDatedPolicy(error);
            },
            () => {
                this.finishedReceivingPrograms();
            }
        );

        // Splunk Logging
        // Reading roles directly from session storage due to current issues with role data service (data.service.ts)

        const userPolicyNumber = this.user.getPolicies()[0] ? this.user.getPolicies()[0].toString() : '';
        const statePrefix = 2;
        const stateSuffix = 3;

        let currentSessionStorageUserRole;
        if (sessionStorage.getItem('isAgent') === 'true') {
          currentSessionStorageUserRole = 'Agent';
        } else if (sessionStorage.getItem('isAdmin') === 'true') {
          currentSessionStorageUserRole = 'Admin';
        } else {
          currentSessionStorageUserRole = 'Member';
        }

        this.logger.info('Landed on Program Select', {
          policyNumber: userPolicyNumber,
          policyState: userPolicyNumber[statePrefix] + userPolicyNumber[stateSuffix],
          role: currentSessionStorageUserRole
        });

        sessionStorage.setItem('login', 'false');
    }

    gatherEffectivePolicyNumbers(): string[] {
        const policyNumberFromSession = sessionStorage.getItem('selectedPolicy');
        if (policyNumberFromSession) {
            return [policyNumberFromSession];
        }
        return this.user.getPolicies();
    }

    receiveProgram(program: Vehicle): void {
        this.orderedVehicles.push(program);
        if ((program.vendorIdCode === 'FORD' || program.vendorIdCode === 'TOYOTA' || program.programType === 'TC') && program.acceptanceStatus === 'N') {
            this.connectedCarRowArray.push({ vin: program.vin, type: program.type });
        }
        this.signalPolicyLoaded();
        this.shouldShowLoadingMessage = false;
        if (program.type === 'sride') {
            this.programTypeArray.push({ vin: program.vin });
        }
    }

    finishedReceivingPrograms(): void {
        this.filterDiscountExceededVehicles();
        this.signalPolicyLoaded();
        if (!this.user.isAdmin()) {
            if (this.connectedCarRowArray && this.connectedCarRowArray.length > 0) {
                this.programSelectService.setConnectedCarDataForLogin(this.connectedCarRowArray);
                this.timeoutModalService.openTermsAndConditionsModal();
            }
        }
        this.shouldShowLoadingMessage = false;
        if (this.hasJustOnePolicyAndItsMobile()) {
            this.router.navigate(['mobileUsers', this.orderedVehicles[0].mobilePolicy], { replaceUrl: true });
        } else if (this.orderedVehicles.length <= 1) {
            this.redirectToLandingPage();
        }
    }

    hasJustOnePolicyAndItsMobile(): boolean {
        if (this.orderedVehicles.length !== 1) {
            return false;
        }
        return this.orderedVehicles[0].enrolledInSRM;
    }

  filterDiscountExceededVehicles = (): void => {
    const vehiclesList = this.orderedVehicles;
    const policyList = this.user.getPolicies();
    this.orderedVehicles = this.orderedVehicles.filter((vehicle) => !this.isCancelledFinalDiscountFortyFiveDaysAgo(vehicle));
    if (this.orderedVehicles.length === 0) {
      vehiclesList.forEach((vehicle) => {
        policyList.filter((policy) => policy !== vehicle.policyNum);
      });
      if (policyList) {
        policyList.forEach((policyData) => {
          this.selfEnrollmentService.checkForMobileEnrollmentForAdminSearch(policyData.toUpperCase()).subscribe((response) => {
            if (response?.mobileEnrollment?.mobileEnrollmentType === 'MobileApp' || response?.mobileEnrollment?.mobileEnrollmentType === 'MobileAppEarlyDiscount') {
              sessionStorage.setItem('selectedPolicy', policyData);
              this.router.navigateByUrl(`/mobileNumberUpdate`);
            }
          }, (error) => {
            this.logger.error(`Telematics enrollment call in admin search failed with ${error}`);
          });
        });
      }
    }
  };

    isCancelledFinalDiscountFortyFiveDaysAgo(vehicle: Vehicle): boolean {
        let isOlderThanFortyFiveDays = false;
        if (!this.user.isAdmin() && vehicle.discounts && vehicle.discounts.length && (/removed/i).test(vehicle.discount.discountType)) {
            // eslint-disable-next-line no-magic-numbers
            isOlderThanFortyFiveDays = moment(environment.futureDate).subtract(45, 'day').isAfter(vehicle.discounts[vehicle.discounts.length - 1].date);
        } else if (!this.user.isAdmin() && (/returned/i).test(vehicle.device.status)) {
            isOlderThanFortyFiveDays = true;
        }
        return isOlderThanFortyFiveDays;
    }

    signalPolicyLoaded = (): void => {
        this.orderedVehicles = this.orderedVehicles.sort((a, b) => {
            if (a.type === b.type) {
                if (a.vin < b.vin) {
                    return -1;
                }
                if (a.vin > b.vin) {
                    return 1;
                }
            } else if (a.type === 'sride') {
                return -1;
            } else if (b.type === 'sride') {
                return 1;
            } else if (a.mobileProgram) {
                return 1;
            } else if (b.mobileProgram) {
                return -1;
            }
            return 0;
        });
        this.vehiclesByRow = this.buildVehiclesRows();
    };

    redirectToLandingPage(): void {
        const vehicleType = BrandingService.getBranding() === 'smartride' ? 'sride' : 'smiles';
        if (this.orderedVehicles === undefined || this.orderedVehicles?.length === 0) {
            this.checkForMobileEnrollmentType();
            return;
        } else if (this.orderedVehicles?.length === 1) {
            const theOnlyVehicle = this.orderedVehicles[0];
            if (theOnlyVehicle.type === vehicleType) {
                this.redirectToDefaultVehiclePage(theOnlyVehicle);
                return;
            } else if (!this.orderedVehicles[0].enrolledInSRM || this.orderedVehicles[0].mobilePolicy === '') {
                this.checkForMobileEnrollmentType();
                return;
            } else {
                this.redirectToMarketingPage();
                return;
            }
        } else if (this.orderedVehicles.length > 1) {
            for (const vehicle of this.orderedVehicles) {
                if (vehicle.type === vehicleType) {
                    return;
                }
            }
            this.redirectToMarketingPage();
            return;
        }
    }

    checkForMobileEnrollmentType(): void {
        this.selfEnrollmentService.checkForMobileEnrollment().subscribe((response) => {
            if (response?.mobileEnrollment?.mobileEnrollmentType === 'MobileApp' || response?.mobileEnrollment?.mobileEnrollmentType === 'MobileAppEarlyDiscount') {
                this.shouldShowSmartRideMobileMessage = true;
                const policy = PolicyNumberFormatService.formatPolicyNumber(this.user.getSelfEnrollPolicy());
                if (!sessionStorage.getItem('selectedPolicy')) {
                  sessionStorage.setItem('selectedPolicy', policy);
                }
                this.router.navigateByUrl(`/mobileNumberUpdate`);
            } else {
                this.shouldShowSmartRideMobileMessage = false;
                this.redirectToMarketingPage();
            }
        }, (error) => {
            this.logger.error(`Telematics enrollment call failed with ${error}`);
            // TODO: Point to a non-prod url
            // eslint-disable-next-line sre/no-hardcoded-nw-url
            this._window.location.replace('https://www.nationwide.com/smartride.jsp');
        });
    }

    redirectToDefaultVehiclePage(vehicle: Vehicle): void {
        if (!this.isNavigationToVehicleProgramPermitted(vehicle)) {
            return;
        }

        sessionStorage.setItem('selectedPolicy', vehicle.policyNum);

        if (vehicle.firstLogin && !this.user.isAdmin() && (vehicle.scoringModel !== 'SM1' && vehicle.scoringModel !== 'SM2' && vehicle.programType !== 'TC')) {
            window.sessionStorage.setItem('LoginBranding', 'smartride');
            if (!sessionStorage.getItem('showedWelcomePage')) {
                sessionStorage.setItem('showedWelcomePage', 'true');
                this.router.navigateByUrl(`/sride/welcomePage`);
                return;
            }
        }
        if (vehicle.scoringModel === 'SM1' || vehicle.scoringModel === 'SM2') {
            window.sessionStorage.setItem('LoginBranding', 'smartmiles');
            if (this.mileage.determineIfGracePeriod(vehicle.ratedMileage)) {
                this.user.alertType = Alert.GracePeriod;
            }
            if (!vehicle.smEnrollDate) {
                this.router.navigateByUrl(`nw/systemError`, { replaceUrl: true });
                return;
            }
            const showMonth = moment.min(moment(vehicle.periodEnd), moment(environment.futureDate)).format('YYYYMMDD');
            this.router.navigateByUrl(`${vehicle.type}/${showMonth}/month/monthlySummary`, { replaceUrl: true });
        } else {
            this.router.navigateByUrl(`${vehicle.type}/summary/month/latest`, { replaceUrl: true });
        }
    }

    redirectToMarketingPage(): void {
        const vehicleType = BrandingService.getBranding();
        this.logger.info('Redirecting to marketing page');
        if (vehicleType === 'smartride') {
            this.selfEnrollmentService.checkForMobileEnrollment().subscribe((response) => {
                if (Object.keys(response).length === 0 || ELIGIBLE_SELF_ENROLLMENT_STATUS.indexOf(response?.mobileEnrollment?.enrollmentStatus) > -1) {
                    this.selfEnrollmentService.getPolicyEligibleDiscounts().subscribe((eligibleDiscounts) => {
                        for (const eligibleDiscount of eligibleDiscounts) {
                            const eligibleDiscountId = eligibleDiscount?.eligibleDiscountId;
                            if (eligibleDiscountId === 'MobileApp' || eligibleDiscountId === 'MobileAppEarlyDiscount') {
                                sessionStorage.setItem('eligibleDiscountId', eligibleDiscountId);
                                this.router.navigateByUrl(`/selfEnrollment`);
                                return;
                            }
                        }
                        // TODO: Point to a non-prod url
                        // eslint-disable-next-line sre/no-hardcoded-nw-url
                        this._window.location.replace('https://www.nationwide.com/smartride.jsp');
                    }, (error) => {
                        this.logger.error(`Get policy eligible discounts call failed with ${error}`);
                        this.router.navigateByUrl(`nw/systemError`, { replaceUrl: true });
                    });
                } else {
                    // TODO: Point to a non-prod url
                    // eslint-disable-next-line sre/no-hardcoded-nw-url
                    this._window.location.replace('https://www.nationwide.com/smartride.jsp');
                }
            },
                (error) => {
                    this.logger.error(`Telematics enrollment call failed with ${error}`);
                    // TODO: Point to a non-prod url
                    // eslint-disable-next-line sre/no-hardcoded-nw-url
                    this._window.location.replace('https://www.nationwide.com/smartride.jsp');
                });
        } else if (vehicleType === 'smartmiles') {
            // TODO: Point to a non-prod url
            // eslint-disable-next-line sre/no-hardcoded-nw-url
            this._window.location.replace('https://www.nationwide.com/personal/insurance/auto/discounts/smartmiles');
        } else {
            this.logger.error(`Unexpected vehicle type "${vehicleType}"`);
            this.router.navigateByUrl(`/404Error`, { replaceUrl: true });
        }
    }

    /* This logic must match ProgramSelectCardComponent.navigationEnabled().
     * TODO: Move this business logic to a more orderly service.
     */
    isNavigationToVehicleProgramPermitted(vehicle: Vehicle): boolean {
        if (this.user.isAdmin()) {
            return true;
        }

        if (vehicle.scoringModel === 'SM1' || vehicle.scoringModel === 'SM2') {
            if (vehicle.ratedMileageDataUnavailable) {
                return false;
            }
        }
        if (vehicle.vehicleDataUnavailable) {
            return false;
        }

        // 45 days past final discount date?
        if (vehicle.discounts?.length > 0) {
            if ((/final/i).test(vehicle.discountType)) {
                for (const discount of vehicle.discounts) {
                    if ((/final/i).test(discount.discountType)) {
                        // eslint-disable-next-line no-magic-numbers
                        if (moment(environment.futureDate).subtract(45, 'day').isAfter(discount.date)) {
                            return false;
                        }
                    }
                }
            }
        }
        return true;
    }

    buildVehiclesRows(): Vehicle[][] {
        const vehicleRowArray = [];
        for (let i = 0; i < this.orderedVehicles.length; i++) {
            const rowNum = Math.floor(i / 2);
            if (i % 2 === 0) {
                vehicleRowArray.push([]);
            }
            vehicleRowArray[rowNum].push(this.orderedVehicles[i]);
        }
        return vehicleRowArray;
    }

    setTitle(newTitle: string): void {
        this.title.setTitle(newTitle);
    }

    // Check if the policy call response contains the error indicating a future dated policy
    checkForFutureDatedPolicy(error: any): void {
        this.futureEffectiveDate = '';
        this.shouldShowFutureDateMessage = false;

        // This is only relevant if it is the only program.
        if (this.orderedVehicles.length) {
            return;
        }

        const messageExpectedRegex = /future effective date of (\d{2}\/\d{2}\/\d{4})/;
        const devError = JSON.stringify(error);
        const match = devError.match(messageExpectedRegex);

        if (match) {
            this.futureEffectiveDate = match[1];
            this.shouldShowFutureDateMessage = true;
            this.shouldShowGeneralErrorMessage = false;
        }
    }

    classifyProgram(vehicle: Vehicle): string {
        if (vehicle.mobileProgram) {
            if (vehicle.mobileProgram.drivers?.length > 0) {
                return 'mobile-final';
            } else {
                return 'mobile-pending';
            }
        } else {
            return 'device';
        }
    }
}
