import { AfterViewChecked, Component, Input, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { WaitSpinnerService } from 'app/service/wait-spinner.service';

@Component({
    selector: 'is-wait-spinner',
    templateUrl: './wait-spinner.component.html',
    styleUrls: ['./wait-spinner.component.css'],
    encapsulation: ViewEncapsulation.None
})

export class WaitSpinnerComponent implements AfterViewChecked, OnInit, OnDestroy {
    @Input() startSpinner = false;
    @Input() waitMessage: string;
    @Input() timeoutAmount: number;
    @Input() autoFocusMessages = false;

    self: any;
    timeoutComplete = false;

    private defaultMessage = 'Please wait while we process your request...';
    private focusedWaitMessage = false;
    private focusedCompleteMessage = false;
    private timeOutId: any;
    private waitMessageChanges: any;

    constructor(private waitSpinnerService: WaitSpinnerService) { }

    ngAfterViewChecked(): void {
        if (this.autoFocusMessages) {
            this.tryAutoFocus();
        }
        if (!this.timeoutAmount) {
            this.timeoutAmount = 2000;
        }
    }

    ngOnInit(): void {
        if (this.waitMessage == null) {
            this.waitMessage = this.defaultMessage;
        }

        if (this.self == null) {
            this.self = this;
        }

        this.timeOutId = setTimeout(() => {
            this.timeoutComplete = true;
        }, this.timeoutAmount);

        this.waitMessageChanges = this.waitSpinnerService.waitMessageChanges$.subscribe((message: string) => {
            this.waitMessage = message ? message : this.defaultMessage;
        });
    }

    ngOnDestroy(): void {
        if (this.waitMessageChanges) {
            this.waitMessageChanges.unsubscribe();
        }
        clearTimeout(this.timeOutId);
    }

    private tryAutoFocus(): void {
        if (this.timeoutComplete) {
            if (!this.focusedWaitMessage) {
                document.getElementById('wait-message-modal').focus();
                this.focusedWaitMessage = true;
            } else if (!this.startSpinner && this.focusedWaitMessage && !this.focusedCompleteMessage) {
                document.getElementById('load-complete-message').focus();
                this.focusedCompleteMessage = true;
            }
        }
    }
}
