import { Component, OnInit } from '@angular/core';
import { environment } from 'environments/environment';
import { ActivatedRoute, Router } from '@angular/router';
import { LoggerService } from 'app/service/logger.service';

@Component({
    selector: 'app-personal-policy-quick-id-zip',
    templateUrl: './quick-id-zip.component.html',
    providers: [LoggerService]
})
export class QuickIdZipComponent implements OnInit {
    clientId: string;
    contextCacheResponse = {};
    continueButtonEvent = '';
    envName: string;
    euaResponse = {};
    isLoadComplete = false;
    missingCacheRedirect = 'smartride/login';
    dobOptionVisible = true;
    header = 'Enter details to continue';
    otp = '';
    contextID = '';
    constructor(
        private router: Router,
        private logger: LoggerService,
        private route: ActivatedRoute,
    ) { }

    ngOnInit(): void {
        this.clientId = environment.contextCacheClientId;
        this.envName = environment.envName;
        this.route.queryParamMap.subscribe((param) => {
          this.otp = param.get('otp') || '';
          this.contextID = param.get('contextId') || '';
          this.logger.info('here', {otp: decodeURIComponent(param.get('otp')), context: this.contextID});
        });
        const queryParams = new URLSearchParams(window.top.location.search);
        this.logger.info('search', {otp: queryParams.get('otp'), context: queryParams.get('contextId')});
        queryParams.forEach((value, key) => {
          this.logger.info('search foreach', {value, key});
        });
        const queryParamsHref = new URLSearchParams(window.location.href);
        this.logger.info('queryParamsHref', {otp: queryParamsHref});

        const queryParamsHash = new URLSearchParams(window.location.hash.slice(1));
        this.logger.info('queryParamsHash', {otp: queryParamsHash});
        this.logger.info('checking', {top: window.top.location.href, href: window.location.href, hash: window.location.hash, search: window.location.search});
        const hash = window.top.location.hash;
        const hashPram = new URLSearchParams(decodeURIComponent(hash.slice(1)));
        this.logger.info('hashPram', {otp: hashPram, value: hashPram.get('otp')});
        const url = window.top.location.href;
        const regex = /[?&#]otp=([^&]*)/;
        const matched = regex.exec(url);
        this.logger.info('matched', {otp: matched, value: decodeURIComponent(matched[1]), otpVal: matched[1]});
        const nested = new URLSearchParams(url);
        this.logger.info('nested', {otp: nested, value: nested.get('otp')});
      }

    setContextCacheResponse(response: any): void {
        this.contextCacheResponse = response;
        if (this.contextCacheResponse['context']) {
            sessionStorage.setItem('selectedPolicy', this.contextCacheResponse['context'].policyNumber);
            this.logger.info('Quick Id ZIP Auth: Context Cache Call successful.');
            this.isLoadComplete = true;
        } else {
            this.logger.error('Quick Id ZIP Auth: Contexct Cache unsuccessful.');
            this.isLoadComplete = true;
        }
    }

    setContinueButtonEvent(response: any): void {
        this.continueButtonEvent = response;
        this.logger.info(`Quick Id ZIP Auth: ${this.continueButtonEvent}`);
    }

    setEUAResponse(response: any): void {
        this.euaResponse = response;
        if (this.euaResponse['successful']) {
            this.logger.info('Quick Id ZIP Auth: EUA call successful');
            this.router.navigateByUrl('smartride/login');
        } else {
            this.logger.error('Quick Id ZIP Auth: EUA response unsuccessful, going to quick-id-search');
            this.router.navigateByUrl('smartride/login');
        }
    }
}
