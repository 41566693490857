import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of, merge } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { environment } from 'environments/environment';
import { ServiceErrorHandler } from 'app/utility/service-error-handler';
import { UserService } from 'app/service/user.service';
import { Device } from 'app/model/device';
import { ContactPreferences } from 'app/model/contact-preferences';
import { Discount } from 'app/model/discount';
import { Timeline } from 'app/model/timeline';
import * as moment from 'moment';
import { PolicyNumberFormatService } from './policy-number-format.service';
import { BrandingService } from './branding.service';
import { APICommonService } from '@nationwide/api-common-service';
import { LoggerService } from './logger.service';

export class VehicleResponse {
  policyNumber: string;
  vin: string;
  device: Device;
  deviceStatusTimeline: Timeline;
  preferences: ContactPreferences;
  discount: Discount;
  vid: string;
  firstLogin: boolean;
  scoreTrend: string;
  participationDiscount: string;
  isEnrolledInSRM: boolean;
  mobilePolicyNumber: string;
  maxDiscount: string;
  vendorIdCode: string;
  scoringModel: string;
  programType: string;
  make: string;
  year: string;
  model: string;
  state: string;
  nickname: string;
  type: string;
  discounts: Discount[];
}

@Injectable()
export class VehicleService {
  vehicleResponseCache = new Map<string, VehicleResponse>();
  currentVehicleEmitter: EventEmitter<VehicleResponse> = new EventEmitter();

  vehiclesURL = 'vehicles';
  srpURL = '/srpReport';
  sreAppType = 'sre';
  adminAppType = 'admin';
  private isSubscribedToLogOutEventEmitter = false;
  connectedCarArray: any;

  // eslint-disable-next-line max-params
  constructor(
    public http: HttpClient,
    public errorHandler: ServiceErrorHandler,
    private userService: UserService,
    private apiCommonService: APICommonService,
    private logger: LoggerService
  ) { }

  // Returns an Observable that will emit vehicle response values in order as they arrive
  getAllVehicles = (vinNumbers: string[]): Observable<any> => {
    if (vinNumbers.length === 1) {
      return this.getVehicleByVin(vinNumbers[0], null);
    }
    const allResponses: Observable<VehicleResponse>[] = [];
    for (const vin of vinNumbers) {
      allResponses.push(this.getVehicleByVin(vin, null));
    }
    return (
      merge(allResponses)
        .pipe(
          catchError((error) => {
            this.errorHandler.handleError('getVehicleByVin');
            return of(error);
          })
        )
    );
  };

  getVehicleByVin(
    vinNumber: string,
    programType: string,
    policyNumber = null
  ): Observable<VehicleResponse> {
    this.subscribeToLogOutEventsIfNecessary();
    if (!policyNumber) {
      policyNumber = sessionStorage.getItem('selectedPolicy');
    }
    const vinNumberWithProgramType = `${vinNumber}-${programType}`;
    if (this.vehicleResponseCache.has(vinNumberWithProgramType)) {
      return of(this.vehicleResponseCache.get(vinNumberWithProgramType)).pipe(
        tap((response) => {
          this.currentVehicleEmitter.emit(response);
        })
      );
    } else {
      const reqHeader = new HttpHeaders()
        .set('X-NW-Message-ID', this.apiCommonService.generateTransactionId())
        .set('client_id', environment.apiKey);

      const urlPrefix = environment.sreApiURL;
      return this.http
        .get(urlPrefix + this.vehiclesURL, {
          headers: reqHeader,
          params: {
            policy: PolicyNumberFormatService.formatPolicyNumber(policyNumber),
            vin: vinNumber,
            search: 'enrolledStatus',
            apptype: 'smiles'
          }
        })
        .pipe(
          map((vehicle) => {
            if (
              BrandingService.getBranding() !== 'smartride' &&
              BrandingService.getBranding() !== 'smartmiles'
            ) {
              return new VehicleResponse();
            }
            let i = 0;
            for (const vehicleData of vehicle['data']) {
              if (
                BrandingService.getBranding() === 'smartmiles' &&
                (vehicleData.scoringModel === 'SM1' || vehicleData.scoringModel === 'SM2')
              ) {
                break;
              } else if (
                BrandingService.getBranding() === 'smartride' &&
                (vehicleData.scoringModel !== 'SM1' && vehicleData.scoringModel !== 'SM2')
              ) {
                break;
              } else {
                i++;
              }
            }
            const vehicleResp = this.mapVehicleResponse(
              vehicle['data'][i],
              vinNumber,
              policyNumber
            );
            vehicleResp.isEnrolledInSRM = vehicle['IS_ENROLLED_IN_SRM'];
            vehicleResp.mobilePolicyNumber = vehicle['mobilePolicy'];
            return vehicleResp;
          }),
          tap((vehicle) =>
            this.vehicleResponseCache.set(
              `${vehicle.vin}-${vehicle.programType}`,
              vehicle
            ),
          ),
          tap((response) => this.currentVehicleEmitter.emit(response)),
          catchError((error) => {
            this.errorHandler.handleError('getVehicleByVin');
            return of(error);
          })
        );
    }
  }

  getVehicleByPolicy(policyNumber: string): Observable<VehicleResponse[]> {
    this.subscribeToLogOutEventsIfNecessary();
    if (!policyNumber) {
      policyNumber = sessionStorage.getItem('selectedPolicy');
    }
    const reqHeader = new HttpHeaders()
      .set('X-NW-Message-ID', this.apiCommonService.generateTransactionId())
      .set('client_id', environment.apiKey);
    if (
      environment.useValidatorService &&
      sessionStorage.getItem('useValidator') === 'true'
    ) {
      this.logger.debug('myLog: vehicle.service.ts: USE VALIDATOR');
      const urlPolicyNumber = policyNumber.replace(/\s/g, '');
      const url = `${environment.validatorUrl}${urlPolicyNumber}/retrieve`;
      const body = {
        serviceName: 'SmartRideExperience_2',
        applicationName: environment.applicationName,
        endpoint:
          'https://pls-telematics-wiremock.apps.nwie.net/policymanagement/personallines/experience/v2/vehicles'
      };
      return this.http.post(url, body, { headers: reqHeader }).pipe(
        map((vehicleJson) => {
          this.logger.debug(`myLog: vehicle.service.ts: ${vehicleJson}`);
          this.logger.debug(`ECN: ${this.userService.getEcn()}`);
          const vehicles: VehicleResponse[] = [];
          if (!vehicleJson) {
            return [];
          }
          const cleanResponse = JSON.parse(
            vehicleJson['cachedResponses'][0]['payload'],
          );
          if (!cleanResponse['data'] || cleanResponse['data'].length < 1) {
            const dummy = this.generateResponseForNoVehicles(cleanResponse);
            if (dummy) {
              vehicles.push(dummy);
            }
          } else {
            for (const vehicleData of cleanResponse['data']) {
              const vehicle = this.mapVehicleResponse(
                vehicleData,
                vehicleData.vin,
                policyNumber
              );
              vehicle.isEnrolledInSRM = cleanResponse['IS_ENROLLED_IN_SRM'];
              vehicle.mobilePolicyNumber = cleanResponse['mobilePolicy'];
              vehicles.push(vehicle);
            }
          }
          return vehicles;
        }),
        tap((vehicles) => {
          if (vehicles) {
            for (const vehicle of vehicles) {
              this.vehicleResponseCache.set(
                `${vehicle.vin}-${vehicle.programType}`,
                vehicle
              );
            }
          }
        }),
        catchError((error) => {
          this.errorHandler.handleError('getVehicleByVin');
          return of(error);
        })
      );
    }

    const urlPrefix = environment.sreApiURL;
    return this.http
      .get(urlPrefix + this.vehiclesURL, {
        headers: reqHeader,
        params: {
          policy: PolicyNumberFormatService.formatPolicyNumber(policyNumber),
          search: 'enrolledStatus',
          apptype: 'smiles'
        }
      })
      .pipe(
        map((vehicleJson) => {
          const vehicles: VehicleResponse[] = [];
          if (!vehicleJson['data'] || vehicleJson['data'].length < 1) {
            const dummy = this.generateResponseForNoVehicles(vehicleJson);
            if (dummy) {
              vehicles.push(dummy);
            }
          } else {
            for (const vehicleData of vehicleJson['data']) {
              const vehicle = this.mapVehicleResponse(
                vehicleData,
                vehicleData.vin,
                policyNumber
              );
              vehicle.isEnrolledInSRM = vehicleJson['IS_ENROLLED_IN_SRM'];
              vehicle.mobilePolicyNumber = vehicleJson['mobilePolicy'];
              vehicles.push(vehicle);
            }
          }
          return vehicles;
        }),
        tap((vehicles) => {
          for (const vehicle of vehicles) {
            this.vehicleResponseCache.set(
              `${vehicle.vin}-${vehicle.programType}`,
              vehicle
            );
          }
        }),
        catchError((error) => {
          this.errorHandler.handleError('getVehicleByVin');
          return of(error);
        }),
      );
  }

  generateResponseForNoVehicles(vehicleJson): VehicleResponse {
    if (!vehicleJson['IS_ENROLLED_IN_SRM']) {
      return null;
    }
    const vehicle = new VehicleResponse();
    vehicle.isEnrolledInSRM = vehicleJson['IS_ENROLLED_IN_SRM'];
    vehicle.mobilePolicyNumber = vehicleJson['mobilePolicy'];
    return vehicle;
  }

  getVehiclesForAdmin(vinNumber: string, policyNbr?: string): Observable<any> {
    if (policyNbr && policyNbr.length > 0) {
      return this.callSREAdminAPIByPolicy(policyNbr);
    } else {
      return this.callSREAdminAPIByVin(vinNumber);
    }
  }

  agentPolicyAccessCheck(policyNumber?: string): Observable<any> {
    if (policyNumber?.length > 0) {
        const accessToken = sessionStorage.getItem('agentAccessToken');
        const url = `${environment.personalLinesPolicyService.url}/policies/${policyNumber}`;
        const options = this.getOptions();
        options.set('authorization', accessToken);
        return this.http.get(url, {headers: options});
    }
  }

  getOptions(): any {
    let requestHeaders = new HttpHeaders()
            // eslint-disable-next-line camelcase
        .set('client_id', environment.apiKey)
        .set('X-NW-Message-ID', this.apiCommonService.generateTransactionId())
        .set('X-NW-Target-Env', environment.personalLinesPolicyService.xNwTargetEnv);
    if (environment.production) {
        requestHeaders = new HttpHeaders()
        // eslint-disable-next-line camelcase
        .set('client_id', environment.apiKey)
        .set('X-NW-Message-ID', this.apiCommonService.generateTransactionId());
    }
    return requestHeaders;
}

  getSRPVehiclesData(policyNbr: string, vin: string): Observable<any> {
    // use wiremock call if we are logged in as a mock user
    let urlPrefix;
    const reqHeader = new HttpHeaders()
      .set('X-NW-Message-ID', this.apiCommonService.generateTransactionId())
      .set('client_id', environment.apigeeEUAPingService.apiKey);
    if (policyNbr) {
      urlPrefix = `${environment.sreApiURL}policies/${policyNbr}${this.srpURL}`;
    } else if (vin) {
      urlPrefix = `${environment.sreApiURL}vehicles/${vin}${this.srpURL}`;
    } else {
      return of('');
    }
    return this.http
      .get(urlPrefix, {
        headers: reqHeader,
        params: {},
        responseType: 'text'
      })
      .pipe(
        catchError((error) => {
          this.errorHandler.handleError('getVehicle');
          return of(error);
        })
      );
  }

  mapVehicleResponse = (
    input: any,
    vin?: string,
    policyNumber?: string
  ): VehicleResponse => {
    const vehicle = new VehicleResponse();
    if (!input) {
      return vehicle;
    }
    vehicle.vin = vin;

    const program = input;

    // Map device properties
    const device = new Device();

    /*
      QC 476011: The device.lastUpdated timestamp is set here to current date,
      this is overwritten to fix the above defect.
      For SMiles, it is overwritten in monthly-summary.component on ngOnInit.
      For SR, it is not yet implemented
    */
    // program.status comes as an array of two parts (Device Status, active/inactive), we only are focusing on the device status
    const deviceStatusIndex = 0;
    const lettersInWordDevice = 6;
    device.buildDevice(program.status[deviceStatusIndex], moment(environment.futureDate).toDate());
    if (
      device.status.slice(0, lettersInWordDevice).toLowerCase() === 'device'
    ) {
      const status = device.status;
      device.status =
        `${status.slice(0, lettersInWordDevice)
        }: ${status.slice(lettersInWordDevice, status.length)}`;
    } else {
      device.status = `Device: ${device.status}`;
    }
    vehicle.device = device;

    // Map discount properties
    if (program.discount && program.discount.length >= 2) {
      vehicle.discount = new Discount(
        moment(environment.futureDate),
        program.discount[0],
        program.discount[1]
      );
    }

    const discounts = program['discounts'];
    let dateArray;
    const discountArray = [];
    if (discounts) {
      dateArray = Object.keys(discounts);
      for (const date of dateArray) {
        const discountTmp = new Discount(
          moment(date),
          discounts[date]['amount'],
          discounts[date]['status']
        );
        discountArray.push(discountTmp);
      }
      this.linkDiscountTrends(discountArray);
      vehicle.discounts = discountArray;
    }

    // Map timeline properties
    if (program.timeline) {
      vehicle.deviceStatusTimeline = new Timeline(
        program.timeline.enroll,
        program.timeline.install,
        program.timeline.complete,
        program.timeline.final,
        program.timeline.ship
      );
    }

    // Map contact preferences
    const preferencesData = program['daily'];
    if (preferencesData) {
      vehicle.preferences = new ContactPreferences();
      vehicle.preferences.build(
        program.email,
        preferencesData.night.email,
        preferencesData.night.text,
        preferencesData.idle.email,
        preferencesData.idle.text,
        preferencesData.brakingAcceleration.email,
        preferencesData.brakingAcceleration.text,
        preferencesData.braking.email,
        preferencesData.braking.text,
        preferencesData.acceleration.email,
        preferencesData.acceleration.text,
        program.nickname,
        program.weeklyEmail
      );
    }

    // Map various remaining properties
    vehicle.policyNumber = program.policyNumber || policyNumber;
    vehicle.vid = program.vid;
    vehicle.state = program.state;
    vehicle.firstLogin = program.firstLogin;
    vehicle.scoreTrend = program.scoreTrend;
    vehicle.participationDiscount = program.participationDiscount;
    vehicle.maxDiscount = program.maxDiscount;
    vehicle.vendorIdCode = program.vendorIdCode;
    vehicle.scoringModel = program.scoringModel;
    vehicle.programType = program.programType;
    vehicle.make = program.make;
    vehicle.year = program.year;
    vehicle.model = program.model;
    vehicle.nickname = program.nickname;

    return vehicle;
  };

  linkDiscountTrends(discounts: Discount[]): void {
    for (const discount of discounts) {
      discount.discountLastWeek = this.findLastDiscountInPeriod(
        discounts,
        discount.date.clone().subtract(1, 'weeks'),
        'week',
      );
      discount.discountLastMonth = this.findLastDiscountInPeriod(
        discounts,
        discount.date.clone().subtract(1, 'months'),
        'month'
      );
    }
  }

  findLastDiscountInPeriod(
    discounts: Discount[],
    target,
    periodUnit: string,
  ): Discount {
    let nearest: Discount = null;
    for (const discount of discounts) {
      if (!discount.date.isSame(target, <any>periodUnit)) {
        continue;
      }
      if (!nearest || discount.date.isAfter(nearest.date)) {
        nearest = discount;
      }
    }
    return nearest;
  }

  callSREAdminAPIByPolicy(policyNbr: string): Observable<any> {
    let vehicles: VehicleResponse[] = [];
    const urlPrefix = environment.sreApiURL;
    const reqHeader = new HttpHeaders()
      .set('X-NW-Message-ID', this.apiCommonService.generateTransactionId())
      .set('client_id', environment.apigeeEUAPingService.apiKey);

    return this.http
      .get(urlPrefix + this.vehiclesURL, {
        headers: reqHeader,
        params: {
          policy: policyNbr,
          search: 'enrolledStatus'
        }
      })
      .pipe(
        map((vehicleJson) => {
          vehicles = [];
          for (const vehicleData of vehicleJson['data']) {
            const vehicle = this.mapVehicleResponse(
              vehicleData,
              vehicleData.vin,
            );
            vehicle.isEnrolledInSRM = vehicleJson['IS_ENROLLED_IN_SRM'];
            vehicle.mobilePolicyNumber = vehicleJson['mobilePolicy'];
            vehicles.push(vehicle);
          }
          return vehicles;
        }),
        catchError((error) => {
          this.errorHandler.handleError('getVehiclesForAdmin');
          return of(error);
        })
      );
  }

  callSREAdminAPIByVin(vinNumber: string): Observable<any> {
    let vehicles: VehicleResponse[] = [];
    const urlPrefix = environment.sreApiURL;
    const reqHeader = new HttpHeaders()
      .set('X-NW-Message-ID', this.apiCommonService.generateTransactionId())
      .set('client_id', environment.apigeeEUAPingService.apiKey);

    return this.http
      .get(urlPrefix + this.vehiclesURL, {
        headers: reqHeader,
        params: {
          vin: vinNumber,
          search: 'enrolledStatus'
        }
      })
      .pipe(
        map((vehicleJson) => {
          vehicles = [];
          if (vehicleJson['data']?.[0]) {
            const vehicle = this.mapVehicleResponse(
              vehicleJson['data'][0],
              vinNumber,
            );
            vehicle.isEnrolledInSRM = vehicleJson['IS_ENROLLED_IN_SRM'];
            vehicle.mobilePolicyNumber = vehicleJson['mobilePolicy'];
            vehicles.push(vehicle);
          }
          return vehicles;
        }),
        catchError((error) => {
          this.errorHandler.handleError('getVehiclesForAdmin');
          return of(error);
        })
      );
  }

  clearVehiclesCache(): void {
    this.vehicleResponseCache.clear();
  }

  updateContactPreferences(vehicle): void {
    this.subscribeToLogOutEventsIfNecessary();
    const existingResponse = this.vehicleResponseCache.get(
      `${vehicle.vin}-${vehicle.programType}`,
    );
    if (!existingResponse) {
      return;
    }
    existingResponse.preferences.nickname = vehicle.name;
    existingResponse.preferences.email = vehicle.contactPreferences.email;
    existingResponse.preferences.sendWeeklyEmail =
      vehicle.contactPreferences.sendWeeklyEmail;
    existingResponse.preferences.sendDailyNightDrivingEmail =
      vehicle.contactPreferences.sendDailyNightDrivingEmail;
    existingResponse.preferences.sendDailyBrakingEmail =
      vehicle.contactPreferences.sendDailyBrakingEmail;
    existingResponse.preferences.sendDailyBrakingAccelerationEmail =
      vehicle.contactPreferences.sendDailyBrakingAccelerationEmail;
    existingResponse.preferences.sendDailyIdleTimeEmail =
      vehicle.contactPreferences.sendDailyIdleTimeEmail;
    existingResponse.preferences.sendDailyAccelerationEmail =
      vehicle.contactPreferences.sendDailyAccelerationEmail;
    this.vehicleResponseCache.set(
      `${vehicle.vin}-${vehicle.programType}`,
      existingResponse,
    );
  }

  subscribeToLogOutEventsIfNecessary(): void {
    if (this.isSubscribedToLogOutEventEmitter) {
      return;
    }
    if (this.userService.logOutEventEmitter) {
      this.userService.logOutEventEmitter.subscribe(() => {
        this.clearVehiclesCache();
      });
      this.isSubscribedToLogOutEventEmitter = true;
    }
  }

  setConnectedCarDataForLogin(connectedcarArray): void {
        this.connectedCarArray = connectedcarArray;
    }

  getConnectedCarDataForLogin(): any {
        return this.connectedCarArray;
    }
}
