import { Component, HostListener, OnInit, ChangeDetectorRef, AfterViewInit, OnDestroy } from '@angular/core';
import * as d3 from 'd3';
import { UserService } from 'app/service/user.service';
import { TimeFrame } from 'app/interfaces/timeframe.interface';
import { TimeframeService } from 'app/service/timeframe.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Vehicle } from 'app/model/vehicle';
import { Moment } from 'moment';
import { TimeFrameUnit } from 'app/enum/timeframe';
import * as moment from 'moment';
import { EbiEventService } from 'app/service/ebi-event.service';
import { RatedMileageService } from 'app/service/rated-mileage.service';
import { TimeframePipe } from 'app/pipes/timeframe.pipe';
import { DecimalPipe } from '@angular/common';
import { Subscription } from 'rxjs';
import { EstimatedPaymentDetails } from 'app/model/estimatedPaymentDetails';
import { TelematicsPaymentDetails } from 'app/model/telematicsPaymentDetails';
import { LoggerService } from 'app/service/logger.service';
import { TelematicsTransactionsService } from 'app/service/telematics-transactions.service';
import { environment } from 'environments/environment';

interface MonthSummary {
  monthStart: Moment;
  monthEnd: Moment;
  miles: number;
  costpermile: string;
}

interface MonthSummary_SM2 {
  monthStart: Moment;
  monthEnd: Moment;
  miles: number;
  costpermile: string;
}

@Component({
  selector: 'app-total-summary',
  templateUrl: './total-summary.component.html',
  styleUrls: ['./total-summary.component.css']
})
export class TotalSummaryComponent implements OnInit, OnDestroy, AfterViewInit {
  /* eslint-disable no-magic-numbers */
  static readonly CHART_HEIGHT = 353;
  static readonly CHART_HEIGHT_SMARTMILES2 = 201;
  static readonly SVG_HEIGHT = 441;
  static readonly SVG_HEIGHT_SMARTMILES2 = 350;
  static readonly SVG_WIDTH_SIX = 862;
  static readonly SVG_WIDTH_SIX_SMARTMILES2 = 970;
  static readonly SVG_WIDTH_TWELVE = 1700;
  static readonly SVG_WIDTH_TWELVE_SMARTMILES2 = 1940;
  /* eslint-enable no-magic-numbers */

  timeFrameSubscription: Subscription;
  options: any;
  termStartDate: Date;
  termEndDate: Date;
  vin: string;
  dataLoaded = false;
  estimate: number;
  totalMiles: number;
  monthlyData: MonthSummary[];
  monthlyData_SM2: MonthSummary_SM2[];
  termMonthCount: number;
  graphWidth: number;
  graphWidth_SM2: number;
  vehicle: Vehicle;
  collapse = false;
  gracePeriod = false;
  shouldDisplayCustomAlert = false;
  isCurrent = false;
  highest: number;
  updatedSmartMilesConnectedCarMessage: string;
  showVehicleNickName = false;
  paymentDetails: EstimatedPaymentDetails;
  paymentDetailsforCostPerMile: TelematicsPaymentDetails;
  mileageData: TelematicsPaymentDetails;
  isSmartMiles2 = false;
  currentTERM = false;
  paymentDataLoaded = false;
  estimatedPremiumStart: string;
  estimatedPremiumEnd: string;
  noData = false;
  monthlyMilesValue: any;
  estimatedMiles: any;
  estimatedAnnualMileage: any;

  // eslint-disable-next-line max-params
  constructor(
    public user: UserService,
    public timeframeService: TimeframeService,
    public route: ActivatedRoute,
    public router: Router,
    public telematicTransactions: TelematicsTransactionsService,
    public ebiEvents: EbiEventService,
    public mileage: RatedMileageService,
    public tfpipe: TimeframePipe,
    public decimal: DecimalPipe,
    public changeDetector: ChangeDetectorRef
  ) { }

  @HostListener('window:NavigateToMonth', ['$event.detail'])
  navigateToMonth(termIndex: any): boolean {
    const termBreakpoints = this.vehicle.termBreakpoints;
    const newTimeframe = <TimeFrame>{
      start: termBreakpoints[termIndex],
      end: termBreakpoints[termIndex + 1],
      unit: TimeFrameUnit.MONTH
    };

    // Exclude months that have not happened yet or ended before the vehicle enroll date
    if (moment(newTimeframe.start) > moment(environment.futureDate) || moment(newTimeframe.end).isBefore(this.vehicle.smEnrollDate, 'day')) {
      return false;
    }

    this.ebiEvents.postEvent('Total Date');
    if (this.isSmartMiles2) {
      this.router.navigateByUrl(
        `/smiles/${moment(newTimeframe.start).format('YYYYMMDD')}/month/monthlySummary`
      );
    } else {
      this.router.navigateByUrl(
        `/smiles/${moment(newTimeframe.start).format('YYYYMMDD')}/month/monthlySummaryDetails`
      );
    }
    this.timeframeService.updateTimeframeRules(newTimeframe);
  }

  ngOnInit(): void {
    this.estimatedMiles = 0;
    this.vin = this.route.snapshot.paramMap.get('vin');
    this.vehicle = this.route.snapshot.data.vehicle;
    this.gracePeriod = this.mileage.determineIfGracePeriod(this.vehicle.ratedMileage);
    this.getVehicleStats(this.vehicle);
    this.setupSubscriptions();
    this.highest = this.timeframeService.getHighestMonthlyMiles();
    if (this.vehicle.scoringModel === 'SM2') {
      this.isSmartMiles2 = true;
    }
  }

  getVehicleStats(vehicle: Vehicle): void {
    const monthsInFullTerm = 12;
    const monthsInHalfTerm = 6;
    const fullYearMonthsComparison = 11;
    this.termStartDate = this.user.getTermStart();
    if (this.termStartDate.getMonth() === this.vehicle.periodStart.getMonth()) {
      this.isCurrent = true;
    } else {
      this.isCurrent = false;
    }
    this.termEndDate = this.user.getTermEnd();
    const daysInYear = 365;
    const daysInMonth = 30;
    this.termMonthCount = moment(this.termStartDate).diff(moment(this.termEndDate), 'month') < fullYearMonthsComparison ? monthsInHalfTerm : monthsInFullTerm;
    this.termMonthCount = this.isSmartMiles2 ? moment(this.termEndDate).diff(moment(this.termStartDate), 'month') < fullYearMonthsComparison ? monthsInHalfTerm : monthsInFullTerm : this.termMonthCount;
    this.graphWidth = this.termMonthCount === monthsInHalfTerm ? TotalSummaryComponent.SVG_WIDTH_SIX : TotalSummaryComponent.SVG_WIDTH_TWELVE;
    this.graphWidth_SM2 = this.termMonthCount === monthsInHalfTerm ? TotalSummaryComponent.SVG_WIDTH_SIX_SMARTMILES2 : TotalSummaryComponent.SVG_WIDTH_TWELVE_SMARTMILES2;
    this.estimate = Math.round(vehicle.annualMileage / daysInYear) * daysInMonth;
    const termBreakpoints = vehicle.calculateTermBreakpointsAdv(this.termStartDate, this.termEndDate);
    const termTimeframe = <TimeFrame>{ start: moment(this.termStartDate), end: moment(this.termEndDate), unit: TimeFrameUnit.TERM };
    const allMonthsSummary = [];
    const allMonthsSummary_SM2 = [];
    for (let i = 0; i < termBreakpoints.length - 1; i++) {
      let endMoment;
      if (i + 1 < termBreakpoints.length - 1) {
        endMoment = termBreakpoints[i + 1].clone().subtract(1, 'day').endOf('day');
      } else {
        endMoment = termBreakpoints[i + 1].clone().endOf('day');
      }

      const monthTimeframe = <TimeFrame>{
        start: termBreakpoints[i],
        end: endMoment,
        unit: TimeFrameUnit.MONTH
      };
      const costPerMile = this.vehicleCostPerMile(monthTimeframe);
      const milesInMonth = this.monthlyMilesValue;

      const thisMonth: MonthSummary = {
        monthStart: termBreakpoints[i],
        monthEnd: termBreakpoints[i + 1],
        miles: milesInMonth,
        costpermile: costPerMile
      };
      allMonthsSummary.push(thisMonth);

      // for SM2.0
      if (this.isSmartMiles2) {
        const costPerMileInMonths = this.vehicleCostPerMile(monthTimeframe);
        const milesInMonth_SM2 = this.monthlyMilesValue;
        const thisMonth_SM2: MonthSummary_SM2 = {
          monthStart: termBreakpoints[i],
          monthEnd: termBreakpoints[i + 1],
          miles: milesInMonth_SM2,
          costpermile: costPerMileInMonths
        };
        allMonthsSummary_SM2.push(thisMonth_SM2);
      }
      this.monthlyData_SM2 = allMonthsSummary_SM2;
    }
    this.totalMiles = vehicle.getTripsInTimeframe(termTimeframe).map((trip) =>
      trip.ratedMileage || 0).reduce((acc, next) =>
        acc + next, 0);

    this.monthlyData = allMonthsSummary;
    this.dataLoaded = true;
    if (this.isSmartMiles2) {
      this.buildGraph_smartMiles2(this.termStartDate);
    } else {
      this.buildGraph(this.termStartDate);
    }
  }

  vehicleCostPerMile(tf): string {
    this.estimatedPremiumStart = tf.start.format('YYYY-MM-DD');
    this.estimatedPremiumEnd = tf.end.format('YYYY-MM-DD');
    this.mileageData = this.telematicTransactions.getTelematicsPaymentDetailsData(
      this.vehicle?.vehicleId, this.estimatedPremiumStart, this.vehicle.vehicleEnrollments
    );
    this.isCurrent = false;
    if (moment(this.estimatedPremiumEnd).isSameOrAfter(moment(environment.futureDate).format('YYYY-MM-DD'))) {
        this.isCurrent = true;
    }
    if (this.mileageData == null) {
      return '--';
    }
    if (
      this.vehicle.paymentDetails &&
      this.vehicle.paymentDetails.length > 0
    ) {
      this.paymentDataLoaded = true;
      this.noData = false;
      const firstPaymentDetail = this.vehicle.paymentDetails[0];
      this.monthlyMilesValue = this.vehicle.paymentDetails[0].actualMiles;
      if (this.estimatedMiles < this.vehicle.paymentDetails[0].estimatedMiles) {
        this.estimatedMiles = this.vehicle.paymentDetails[0].estimatedMiles;
        this.estimatedAnnualMileage = this.vehicle.paymentDetails[0].annualMileage;
      }
      return `$${firstPaymentDetail.nonWeightedCostPerMile}`;
    } else if (this.mileageData) {
      this.paymentDetailsforCostPerMile = this.mileageData;
      this.monthlyMilesValue = this.mileageData.actualMiles;
      if (this.estimatedMiles < this.mileageData.estimatedMiles) {
        this.estimatedMiles = this.mileageData.estimatedMiles;
        this.estimatedAnnualMileage = this.mileageData.annualMileage;
      }
      if (this.isCurrent) {
        this.monthlyMilesValue = this.vehicle.getTripsInTimeframe(tf).map((trip) =>
        trip.ratedMileage || 0).reduce((acc, next) =>
          acc + next, 0);
      }
      this.paymentDataLoaded = true;
      this.noData = false;
    } else {
      this.noData = true;
      this.paymentDataLoaded = true;
      return '--';
    }

    if (this.paymentDetailsforCostPerMile.nonWeightedCostPerMile == null) {
        return '--';
    }

    return `$${this.paymentDetailsforCostPerMile.nonWeightedCostPerMile}`;
  }

  buildGraph = (vehicleEnrollDate: Date): void => {
    this.highest = this.timeframeService.getHighestMonthlyMiles();
    // calculate the highest data point to accurately scale the rest of the graph
    const topPadding = 1.35;
    const ymax = this.estimate * topPadding;
    // Generate the actual SVG component
    d3.select('#graph-main-content').select('svg').remove();
    const svg = d3.select('#graph-main-content').append('svg')
      .attr('width', this.graphWidth)
      .attr('height', TotalSummaryComponent.SVG_HEIGHT)
      .attr('draggable', false);

    // Define the fade away gradient at the top of bars for later use (belongs in the beginning of the SVG definition)
    const gradient = svg.append('defs')
      .append('linearGradient')
      .attr('id', 'FadeGradient')
      .attr('x1', '0%')
      .attr('x2', '0%')
      .attr('y1', '0%')
      .attr('y2', '100%');

    // Cotrol fade gradient opacity: page BG color with 0% transparency to 100% transparency
    gradient.append('stop')
      .attr('offset', '0%')
      .attr('stop-color', '#e7e7e7')
      .attr('stop-opacity', '100');

    gradient.append('stop')
      .attr('offset', '100%')
      .attr('stop-color', '#e7e7e7')
      .attr('stop-opacity', '0');

    // Create a 1-N term ordinal scale to set bar positions
    const rangeBottom = 0.1;
    const rangeTop = 0.35;
    const barSpacing = d3.scaleBand<number>()
      .domain(d3.range(this.termMonthCount))
      .rangeRound([0, this.graphWidth])
      .padding(rangeTop);

    const y = d3.scaleLinear()
      .domain([0, ymax])
      .range([0, TotalSummaryComponent.CHART_HEIGHT]);

    // Create the actual bars in the graph
    const barWidth = 100;
    svg.selectAll('.bar')
      .data(this.monthlyData)
      .enter()
      .append('rect')
      .style('z-index', '0')
      .style('fill', (d) => {
        if (d.miles <= this.estimate) {
          return '#80c500';
        } else if (d.miles > this.estimate && d.miles < this.highest) {
          return '#fec558';
        } else {
          return '#ee3741';
        }
      })
      .attr('class', 'bar')
      .attr('x', (_d, i) => barSpacing(i))
      .attr('width', barWidth)
      .attr('y', (d) => {
        if (d.miles <= this.estimate) {
          return TotalSummaryComponent.CHART_HEIGHT - y(d.miles);
        } else if (d.miles > this.estimate && d.miles < this.highest) {
          return TotalSummaryComponent.CHART_HEIGHT - y(d.miles);
        } else {
          return 0;
        }
      })
      .attr('height', (d) => {
        if (d.miles <= this.estimate) {
          return y(d.miles);
        } else if (d.miles > this.estimate && d.miles < this.highest) {
          return y(d.miles);
        } else if (d.miles >= this.highest) {
            return TotalSummaryComponent.CHART_HEIGHT;
        } else {
          return 0;
        }
      }); // (d.miles / ymax) * TotalSummaryComponent.CHART_HEIGHT; }); // y(d.miles); });

    // estimate dashed line
    const dashedLineHeight = 92;
    svg.append('line')
      .attr('x1', 0)
      .attr('y1', dashedLineHeight)
      .attr('x2', this.graphWidth)
      .attr('y2', dashedLineHeight)
      .style('stroke', 'black')
      .style('stroke-dasharray', '15 10')
      .style('z-index', '10');

    // highest estimate dashed line
    const highdashedLineHeight = 1;
    svg.append('line')
      .attr('x1', 0)
      .attr('y1', highdashedLineHeight)
      .attr('x2', this.graphWidth)
      .attr('y2', highdashedLineHeight)
      .style('stroke', 'black')
      .style('stroke-dasharray', '15 10')
      .style('z-index', '10');

    // slightly darker background behind mile values
    const darkBackgroundYValue = 353;
    const darkBackgroundHeight = 39;
    svg.append('rect')
      .attr('x', 0)
      .attr('y', darkBackgroundYValue)
      .attr('height', darkBackgroundHeight)
      .attr('width', this.graphWidth)
      .attr('fill', '#ccc');

    // apply the fade
    const fadeHeight = 40;
    const fadestart = -10;
    svg.append('rect')
      .attr('x', 0)
      .attr('y', fadestart)
      .attr('width', this.graphWidth)
      .attr('height', fadeHeight)
      .attr('fill', 'url(#FadeGradient)');

    // paint the mileage amounts below the bars
    const mileLabelSpacingWidthOffset = 52;
    const mileLabelpadding = 0.50;
    const milesLabelSpacing = d3.scalePoint<number>()
      .domain(d3.range(this.termMonthCount))
      .range([0, this.graphWidth - mileLabelSpacingWidthOffset])
      .padding(mileLabelpadding);
    const mileageLabelXOffset = 32;
    const mileageLabelYOffset = 377;
    svg.selectAll('.mileage-label')
      .data(this.monthlyData)
      .enter().append('text')
      .attr('class', (d, _i) => {
        if (d.monthStart.isAfter(moment(environment.futureDate), 'day') || d.monthEnd.isBefore(vehicleEnrollDate, 'day')) {
          return 'not-selectable';
        } else {
          return 'not-selectable mileage-label-active';
        }
      })
      .attr('fill', (d, _i) => {
        if (d.monthStart.isAfter(moment(environment.futureDate), 'day') || d.monthEnd.isBefore(vehicleEnrollDate, 'day')) {
          return '#3a3a3a';
        } else {
          return '#000';
        }
      })
      .attr('x', (_d, i) => milesLabelSpacing(i) + mileageLabelXOffset)
      .attr('text-anchor', 'middle')
      .attr('y', mileageLabelYOffset)
      .text((d, _i) => this.decimal.transform(d.miles));

    // paint the dates the values apply to
    const dateLabelWidthOffsetForGraph = 14;
    const dateLabelFactor = 0.68;
    const datesLabelSpacing = d3.scalePoint<number>()
      .domain(d3.range(this.termMonthCount))
      .range([0, this.graphWidth - dateLabelWidthOffsetForGraph])
      .padding(dateLabelFactor);

    const dataLabelBubbleWidth = 120;
    const dataLabelBubbleHeight = 40;
    const dataLabelBubbleY = 400;
    const dataLabelBubbleRAxis = 5;
    const dataLabelBubbleXOffset = 58;
    svg.selectAll('.date-label-bubble')
      .data(this.monthlyData)
      .enter().append('rect')
      .attr('x', (_d, i) => datesLabelSpacing(i) - dataLabelBubbleXOffset)
      .attr('y', dataLabelBubbleY)
      .attr('z', 1)
      .attr('rx', dataLabelBubbleRAxis)
      .attr('ry', dataLabelBubbleRAxis)
      .attr('width', dataLabelBubbleWidth)
      .attr('height', dataLabelBubbleHeight)
      .attr('stroke', (d, _i) => {
        if (d.monthStart.isAfter(moment(environment.futureDate), 'day') || d.monthEnd.isBefore(vehicleEnrollDate, 'day')) {
          return 'rgba(0,0,0,.35)';
        } else {
          return '#0072CF';
        }
      })
      .attr('stroke-width', 1)
      .attr('fill', (d, _i) => {
        if (d.monthStart.isAfter(moment(environment.futureDate), 'day') || d.monthEnd.isBefore(vehicleEnrollDate, 'day')) {
          return 'rgba(0,0,0,0)';
        } else {
          return 'white';
        }
      })
      .attr('onclick', (_d, i) => `window.dispatchEvent(new CustomEvent('NavigateToMonth',{'detail':${i}}))`)
      .style('cursor', 'pointer');

    const dateLabelHeight = 425;
    const dateLabelWidthOffset = 47;
    svg.selectAll('.date-label-text')
      .data(this.monthlyData)
      .enter().append('text')
      .attr('fill', (d, _i) => {
        if (d.monthStart.isAfter(moment(environment.futureDate), 'day') || d.monthEnd.isBefore(vehicleEnrollDate, 'day')) {
          return '#606060';
        } else {
          return '#0072CF';
        }
      })
      .attr('class', 'date-label not-selectable')
      .attr('x', (_d, i) => datesLabelSpacing(i) - dateLabelWidthOffset)
      .attr('y', dateLabelHeight)
      .attr('z', 2)
      .text((d, i) => {
        let endDate;
        if (i < this.monthlyData.length - 1) {
          endDate = d.monthEnd.clone().subtract(1, 'day').format('MM/DD');
        } else {
          endDate = d.monthEnd.clone().format('MM/DD');
        }
        return `${d.monthStart.format('MM/DD')} - ${endDate}`;
      })
      .attr('onclick', (_d, i) => `window.dispatchEvent(new CustomEvent('NavigateToMonth',{'detail':${i}}))`);

    // divider between mile values and dates
    const dividerHeight = 390;
    svg.append('line')
      .attr('x1', 0)
      .attr('y1', dividerHeight)
      .attr('x2', this.graphWidth)
      .attr('y2', dividerHeight)
      .style('stroke', '#333')
      .style('stroke-width', '2')
      .style('z-index', '10');
  };

  buildGraph_smartMiles2 = (vehicleEnrollDate: Date): void => {
    this.highest = this.timeframeService.getHighestMonthlyMiles();
    // calculate the highest data point to accurately scale the rest of the graph
    const topPadding = 1.35;
    const ymax = this.estimate * topPadding;
    // Generate the actual SVG component
    d3.select('#graph-main-content_SM2').select('svg').remove();
    const svg = d3.select('#graph-main-content_SM2').append('svg')
      .attr('width', this.graphWidth_SM2)
      .attr('height', TotalSummaryComponent.SVG_HEIGHT_SMARTMILES2)
      .attr('draggable', false);

    // Define the fade away gradient at the top of bars for later use (belongs in the beginning of the SVG definition)
    const gradient = svg.append('defs')
      .append('linearGradient')
      .attr('id', 'FadeGradient')
      .attr('x1', '0%')
      .attr('x2', '0%')
      .attr('y1', '0%')
      .attr('y2', '100%');

    // Cotrol fade gradient opacity: page BG color with 0% transparency to 100% transparency
    gradient.append('stop')
      .attr('offset', '0%')
      .attr('stop-color', '#e7e7e7')
      .attr('stop-opacity', '100');

    gradient.append('stop')
      .attr('offset', '100%')
      .attr('stop-color', '#e7e7e7')
      .attr('stop-opacity', '0');

    // Create a 1-N term ordinal scale to set bar positions
    const rangeBottom = 0.1;
    const rangeTop = 0.35;
    const barSpacing = d3.scaleBand<number>()
      .domain(d3.range(this.termMonthCount))
      .rangeRound([0, this.graphWidth_SM2])
      .padding(rangeTop);

    const y = d3.scaleLinear()
      .domain([0, ymax])
      .range([0, TotalSummaryComponent.CHART_HEIGHT_SMARTMILES2]);

    // Create the actual bars in the graph
    const barWidth = 120;
    svg.selectAll('.bar')
      .data(this.monthlyData)
      .enter()
      .append('rect')
      .style('z-index', '0')
      .style('fill', (d) => {
        if (d.miles <= this.estimate) {
          return '#0047BB';
        } else if (d.miles > this.estimate && d.miles < this.highest) {
          return '#0047BB';
        } else {
          return '#0047BB';
        }
      })
      .attr('class', 'bar')
      .attr('x', (_d, i) => barSpacing(i))
      .attr('width', barWidth)
      .attr('y', (d) => {
        if (d.miles <= this.estimate) {
          return TotalSummaryComponent.CHART_HEIGHT_SMARTMILES2 - y(d.miles);
        } else if (d.miles > this.estimate && d.miles < this.highest) {
          return TotalSummaryComponent.CHART_HEIGHT_SMARTMILES2 - y(d.miles);
        } else {
          return 0;
        }
      })
      .attr('height', (d) => {
        if (d.miles <= this.estimate) {
          return y(d.miles);
        } else if (d.miles > this.estimate && d.miles < this.highest) {
          return y(d.miles);
        } else if (d.miles >= this.highest) {
            return TotalSummaryComponent.CHART_HEIGHT_SMARTMILES2;
        } else {
          return 0;
        }
      }); // (d.miles / ymax) * TotalSummaryComponent.CHART_HEIGHT; }); // y(d.miles); });

    // slightly darker background behind mile values
    const darkBackgroundYValue = 201;
    const darkBackgroundHeight = 48;
    svg.append('rect')
      .attr('x', 0)
      .attr('y', darkBackgroundYValue)
      .attr('height', darkBackgroundHeight)
      .attr('width', this.graphWidth_SM2)
      .attr('fill', '#DCDFDF');

    // slightly darker background behind cost per mile values
    const slightDarkBackgroundYValue = 249;
    svg.append('rect')
      .attr('x', 0)
      .attr('y', slightDarkBackgroundYValue)
      .attr('height', darkBackgroundHeight)
      .attr('width', this.graphWidth_SM2)
      .attr('fill', '#E8E9EA');

    // paint the mileage amounts below the bars
    const mileLabelSpacingWidthOffset = 20;
    const mileLabelSpacingPadding = 0.63;
    const milesLabelSpacing = d3.scalePoint<number>()
      .domain(d3.range(this.termMonthCount))
      .range([0, this.graphWidth_SM2 - mileLabelSpacingWidthOffset])
      .padding(mileLabelSpacingPadding);

    const mileageLabelXOffset = 16;
    const mileageLabelYOffset = 228;
    svg.selectAll('.mileage-label')
      .data(this.monthlyData)
      .enter().append('text')
      .attr('class', (d, _i) => {
        if (d.monthStart.isAfter(moment(environment.futureDate), 'day') || d.monthEnd.isBefore(vehicleEnrollDate, 'day')) {
          return 'sm2_total_graph not-selectable';
        }
      })
      .attr('fill', (d, _i) => {
        if (d.monthStart.isAfter(moment(environment.futureDate), 'day') || d.monthEnd.isBefore(vehicleEnrollDate, 'day')) {
          return '#3a3a3a';
        } else {
          return '#000';
        }
      })
      .attr('x', (_d, i) => milesLabelSpacing(i) + mileageLabelXOffset)
      .attr('text-anchor', 'middle')
      .attr('y', mileageLabelYOffset)
      .text((d, _i) => this.decimal.transform(d.miles));

    // paint the cost per miles amounts below the bars
    const costPerMileLabelSpacingWidthOffset = 20;
    const costPerMileLabelSpacingPadding = 0.6;
    const costPerMileLabelSpacing = d3.scalePoint<number>()
      .domain(d3.range(this.termMonthCount))
      .range([0, this.graphWidth_SM2 - costPerMileLabelSpacingWidthOffset])
      .padding(costPerMileLabelSpacingPadding);
    const costPerMileLabelXOffset = 16;
    const costPerMileLabelYOffset = 276;
    svg.selectAll('.costPerMile-label')
      .data(this.monthlyData_SM2)
      .enter().append('text')
      .attr('class', (d, _i) => 'sm2_total_graph not-selectable')
      .attr('fill', (d, _i) => {
        if (d.monthStart.isAfter(moment(environment.futureDate), 'day') || d.monthEnd.isBefore(vehicleEnrollDate, 'day')) {
          return '#3a3a3a';
        } else {
          return '#000';
        }
      })
      .attr('x', (_d, i) => costPerMileLabelSpacing(i) + costPerMileLabelXOffset)
      .attr('text-anchor', 'middle')
      .attr('y', costPerMileLabelYOffset)
      .text((d, _i) => `${d.costpermile}`);

    // paint the dates the values apply to
    const dateLabelWidthOffsetForGraph = 12;
    const dateLabelFactor = 0.75;
    const datesLabelSpacing = d3.scalePoint<number>()
      .domain(d3.range(this.termMonthCount))
      .range([0, this.graphWidth_SM2 - dateLabelWidthOffsetForGraph])
      .padding(dateLabelFactor);

    const dateLabelHeight = 324;
    const dateLabelWidthOffset = 40;
    svg.selectAll('.date-label-text')
      .data(this.monthlyData)
      .enter().append('text')
      .attr('fill', (d, _i) => {
        if (d.monthStart.isAfter(moment(environment.futureDate), 'day') || d.monthEnd.isBefore(vehicleEnrollDate, 'day')) {
          return '#707070';
        } else {
          return '#0047BB';
        }
      })
      .attr('font-weight', (d, _i) => {
        if (d.monthStart.isAfter(moment(environment.futureDate), 'day') || d.monthEnd.isBefore(vehicleEnrollDate, 'day')) {
          return '400';
        } else {
          return '500';
        }
      })
      .attr('font-size', (d, _i) => '16px')
      .attr('line-height', (d, _i) => {
        if (d.monthStart.isAfter(moment(environment.futureDate), 'day') || d.monthEnd.isBefore(vehicleEnrollDate, 'day')) {
          return '31px';
        } else {
          return '24px';
        }
      })
      .attr('class', 'sm2_date-label not-selectable')
      .attr('x', (_d, i) => datesLabelSpacing(i) - dateLabelWidthOffset)
      .attr('y', dateLabelHeight)
      .attr('z', 2)
      .text((d, i) => {
        let endDate;
        if (i < this.monthlyData.length - 1) {
          endDate = d.monthEnd.clone().subtract(1, 'day').format('MM/DD');
        } else {
          endDate = d.monthEnd.clone().format('MM/DD');
        }
        return `${d.monthStart.format('MM/DD')} - ${endDate}`;
      })
      .attr('onclick', (_d, i) => `window.dispatchEvent(new CustomEvent('NavigateToMonth',{'detail':${i}}))`);
  };

  setupSubscriptions = (): void => {
    this.timeFrameSubscription = this.timeframeService.timeframeChange$.subscribe((_tf) => {
      this.getVehicleStats(this.vehicle);
    }
    );
  };

  ngAfterViewInit(): void {
    this.setupSubscriptions();
    this.changeDetector.detectChanges();
  }
  ngOnDestroy(): void {
    if (this.timeFrameSubscription.closed === false) {
      this.timeFrameSubscription.unsubscribe();
      this.timeFrameSubscription = null;
    }
  }

  shouldProvideEditLinkForNickname(): boolean {
    return !(this.vehicle?.contactPreferences && this.isNicknameValid(this.vehicle.contactPreferences.nickname));
  }

  isNicknameValid(nickname: string): boolean {
    return !!nickname?.trim();
  }

  getLinkForEditNickname(): string {
    return '/preferences/smiles/';
  }

  isSmartMilesConnectedCar(): boolean {
    return (this.vehicle.scoringModel === 'SM1' || this.vehicle.scoringModel === 'SM2') && (this.vehicle.vendorIdCode === 'FORD' || this.vehicle.vendorIdCode === 'TOYOTA');
  }

  isSmartMilesConnectedCarCA(): boolean {
    return this.isSmartMilesConnectedCar() && this.vehicle.state === 'CA';
  }

  getUpdatedSmartMilesConnectedCarMessage(): string {
    this.updatedSmartMilesConnectedCarMessage = 'device tracks';
    if (this.isSmartMilesConnectedCar()) {
      this.updatedSmartMilesConnectedCarMessage = 'vehicle measures';
    }
    return this.updatedSmartMilesConnectedCarMessage;
  }
}
